<template>
  <section>
    <Toast />
    <div class="h-full bg-white p-5 mt-10 rounded-xl border">
      <div class="w-full">
        <button @click="$router.back" class="flex gap-2 text-green-600">
          <i class="pi pi-arrow-circle-left"></i>
          <p>Atras</p>
        </button>
      </div>
      <div class="grid grid-cols-5 grid-flow-col gap-12">
        <div class="flex items-center gap-2 font-bold">
          <i class="pi pi-replay"></i>
          <p class="text-lg">
            Historial
          </p>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-2 my-3">
        <div class="w-full">
          <label class="text-xs text-gray-600" for="">Orden(es)</label>
          <InputText class="w-full " id="numero_orden" @keyup.enter="agregarOv" v-model="ordenSeleccionada" @keydown="validacionOrdenes($event)" />
          <div class="flex flex-wrap gap-1 mt-2 max-h-24 overflow-y-auto">
            <div v-for="(orden, i) in ordenesFiltradas" :key="i" class="">
              <div class="flex items-center p-1 bg-blue-200 text-blue-600 rounded-md">
                <p class="text-xs">{{ orden }}</p>
                <button @click="eliminarOrden(orden)" class="mx-1"><i class="pi pi-times text-xs"></i></button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label for="">Estado</label>
          <Dropdown v-model="filtros.Status" :options="estados" optionLabel="name" optionValue="code" placeholder="Seleccione" panelClass="text-sm" class="w-full text-sm" />
        </div>
        <div class="">
          <label for="">Fecha inicio</label>
          <input type="date" v-model="filtros.fecha_inicio" class="p-2 border w-full rounded-md">
        </div>
        <div class="">
          <label for="">Fecha fin</label>
          <input type="date" v-model="filtros.fecha_fin" class="p-2 border w-full rounded-md">
        </div>
        <div size="small" class="my-auto gap-2 items-start pt-5 flex h-full">
          <Button label="Buscar" @click="listarFacturas" icon="pi pi-search" class="w-6/12 text-sm" />
          <Button label="Limpiar" @click="limpiarFiltros" icon="pi pi-times" severity="secondary" class="w-6/12 text-sm" />
        </div>
        <div size="small" class="my-auto items-start pt-5 flex h-full">
          <Button :disabled="!listadoFacturas.rows.some(e => e.Status === 3)" label="Reenviar todo" @click="reenviarTodo" severity="warning" icon="pi pi-replay" class="w-full text-sm" />
        </div>
      </div>
      <div>
        <DataTable headerClass="bg-red-600" :value="listadoFacturas.rows" class="p-datatable-sm text-sm" tableStyle="min-width: 50rem">
          <Column field="DocDate" header="Fecha ov"></Column>
          <Column field="NumOv" header="#OV"></Column>
          <Column field="FechaEnvio" header="Fecha envio">
            <template #body="slotProps">
              <p>{{ dayjs(slotProps.data.FechaEnvio).format('YYYY-MM-DD') }}</p>
            </template>
          </Column>
          <Column field="DocNum" header="Factura">
            <template #body="slotProps">
              <p>{{ slotProps.data.DocNum }}</p>
            </template>
          </Column>
          <Column field="FechaFactura" header="Fecha factura">
            <template #body="slotProps">
              <p>{{ slotProps.data.DocNum ? slotProps.data.FechaFactura : '' }}</p>
            </template>
          </Column>
          <Column field="ServiceLayerResponse" header="Comentario"></Column>
          <Column field="fullName" header="Enviado por"></Column>
          <Column field="Status" header="Estado">
            <template #body="slotProps">
              <button class="flex gap-2 text-blue-600 bg-blue-300 py-2 px-4 rounded-md" v-if="slotProps.data.Status === 0">
                <i class="pi pi-cloud-upload"></i>
                <p class="font-bold">Enviar</p>
              </button>
              <button class="flex gap-2 text-gray-600" v-if="slotProps.data.Status === 1">
                <ProgressSpinner style="width: 1rem; height: 1rem;" fill="white" strokeWidth="8" />
                <p>Procesando</p>
              </button>
              <button class="flex gap-2 text-green-600" v-if="slotProps.data.Status === 2">
                <i class="pi pi-check-circle"></i>
                <p class="font-bold">Terminado</p>
              </button>
              <button @click="reenviar(slotProps.data.NumOv)" class="flex gap-2 text-red-600 bg-red-300 py-2 px-4 rounded-md" v-if="slotProps.data.Status === 3">
                <i class="pi pi-cloud-upload"></i>
                <p class="font-bold">Reenviar</p>
              </button>
              <p class="font-bold text-red-500" v-if="slotProps.data.Status !== 0 && slotProps.data.Status !== 1 && slotProps.data.Status !== 2 && slotProps.data.Status !== 3">!Estado desconocido</p>
            </template>
          </Column>
        </DataTable>
        <Paginator v-model:first="offset"
          :rows="limit"
          :totalRecords="listadoFacturas.count"
          :rowsPerPageOptions="[2,10,20,30,100]"
          @page="onPage($event)"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { ref, onMounted, computed } from 'vue'
import dayjs from 'dayjs'
import HistorialService from '../../services/historial.service'
import ReenviarService from '../../services/reenviar.service'
import { useToast } from 'primevue/usetoast'
import Swal from 'sweetalert2'
export default {
  name: 'SegFacturacionHistorialEvento',
  setup () {
    const _Facturas = ref(new HistorialService())
    const _ReenviarService = ref(new ReenviarService())
    const listadoFacturas = ref({
    count: 10,
    rows: [
      {
        NumOv: 546546,
        DocNum: '20/10/2023 10:02am',
        DocDate: '17/10/2023 10:02am',
        factura: 646513654,
        fecha_factura: '20/10/2023 10:02am',
        comentarios: 'proof',
        Status: 3,
        fullName: 'Pepito Perez'
        }
      ]
    })
    const limit = ref(10)
    const modalidad = ref(null)
    const ordenSeleccionada = ref()
    const offset = ref(0)
    const ordenesFiltradas = ref([])
    const toast = useToast()
    const params = computed(() => {
      const data = {
        offset: offset.value,
        limit: limit.value,
        filters: {
          ...filtros.value
        }
      }
      for (const [key, value] of Object.entries(data.filters)) {
        if (!value) delete data.filters[key]
      }
      if (ordenesFiltradas.value.length > 0) data.filters.ordenes = ordenesFiltradas.value
      return data
    })
    const estados = ref([
      {
        name: 'Procesando',
        code: 1
      },
      {
        name: 'Terminado',
        code: 2
      },
      {
        name: 'Error',
        code: 3
      }
    ])
    const limpiarFiltros = () => {
      filtros.value = {
        ordenes: ordenesFiltradas.value,
        Status: null,
        fecha_inicio: dayjs().format('YYYY-MM-DD'),
        fecha_fin: dayjs().format('YYYY-MM-DD')
      }
      ordenesFiltradas.value = []
      listarFacturas()
    }
    const filtros = ref({
      Status: null,
      fecha_inicio: dayjs().format('YYYY-MM-DD'),
      fecha_fin: dayjs().format('YYYY-MM-DD')
    })
    const agregarOv = () => {
      if (ordenSeleccionada.value) {
        for (const i of ordenSeleccionada.value.split(' ')) {
          if (ordenesFiltradas.value && ordenesFiltradas.value.length) {
            if (!ordenesFiltradas.value.some(a => a === i)) {
              i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
            }
          } else {
            if (i !== '') {
              i !== '' ? ordenesFiltradas.value.push(i) : ordenesFiltradas.value.push()
            }
          }
        }
      }
      ordenSeleccionada.value = ''
    }
    const validacionOrdenes = (e) => {
      if (/[^0-9\s]/.test(e.key)) {
        if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
          if (e.key === 'v' && !e.ctrlKey) {
            e.preventDefault()
          }
        } else {
          e.preventDefault()
        }
      }
    }
    const eliminarOrden = (numeroOv) => {
      const index = ordenesFiltradas.value.findIndex(a => a === numeroOv)
      ordenesFiltradas.value.splice(index, 1)
    }
    const listarFacturas = () => {
        _Facturas.value.getHistorial(params.value).then(({ data }) => {
            listadoFacturas.value = data
        })
    }
    const onPage = (event) => {
        listarFacturas()
    }
    const reenviarTodo = () => {
      Swal.fire({
        title: 'Adventencia',
        icon: 'warning',
        text: '¿Esta seguro de reenviar todas las ordenes en estado error?',
        showCancelButton: true,
        confirmButtonText: 'Reenviar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          _ReenviarService.value.reenviarAll().then(() => {
            Swal.fire('Reenviado', 'Todas las ordenes en estado error, se reenviaron a facturar correctamente', 'success').then(() => {
              listarFacturas()
            })
          })
        }
      })
    }
    const reenviar = (ov) => {
      _ReenviarService.value.reenviar({ ovs: [ov] }).then(() => {
        toast.add({ severity: 'info', summary: 'Enviado', detail: `Se reenvio a facturar la orden ${ov}`, life: 3000 })
        listarFacturas()
      })
    }
    onMounted(() => {
        listarFacturas()
    })
    return {
        listadoFacturas,
        dayjs,
        limit,
        listarFacturas,
        modalidad,
        offset,
        limpiarFiltros,
        onPage,
        reenviar,
        estados,
        filtros,
        eliminarOrden,
        agregarOv,
        ordenSeleccionada,
        ordenesFiltradas,
        validacionOrdenes,
        reenviarTodo
    }
  }
}
</script>
<style scoped>
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(189, 189, 189);
      border-radius: 9999px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    ::-webkit-scrollbar {
      width: 0.2rem;
    }
</style>
