import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_FACTURACION_MASIVA
export default class HistorialService {
  getHistorial (params) {
    return http.get(`${baseUrl}/historial`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
